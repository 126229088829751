/* eslint-disable react/jsx-props-no-spreading */
import React, { forwardRef } from 'react';
import MaterialTable from 'material-table';
import PropTypes from 'prop-types';
import {
  ArrowDownward,
  ChevronLeft,
  ChevronRight,
  Clear,
  FilterList,
  FirstPage,
  LastPage,
  SaveAlt,
  Search,
  Delete,
} from '@mui/icons-material';
import { useConfirm } from 'material-ui-confirm';
import _ from 'lodash';
import { createTheme, ThemeProvider, Typography } from '@mui/material';
import colors from '../colors.styles';

export default function SocialChampsTable({
  socialChamps,
  isLoading,
  onDelete,
}) {
  const confirm = useConfirm();

  const theme = createTheme({
    palette: {
      primary: {
        main: colors.base.green,
      },
      secondary: {
        main: colors.base.dark_yellow,
      },
    },
  });

  const commonStyle = {
    padding: '5px 10px',
  };
  const columns = [
    {
      title: 'WIN',
      field: 'win',
      cellStyle: { ...commonStyle, textAlign: 'center' },
      headerStyle: { textAlign: 'center' },
    },
    {
      title: 'Name',
      field: 'name',
      cellStyle: { ...commonStyle },
      render: ({ name }) => name.split(' ').map(_.capitalize).join(' '),
    },
    {
      title: 'Email',
      field: 'email',
      cellStyle: { ...commonStyle },
    },
    {
      title: 'Store Number',
      field: 'store_number',
      cellStyle: { ...commonStyle, textAlign: 'center' },
      headerStyle: { textAlign: 'center' },
    },
    {
      title: 'Start Date',
      field: 'start_date',
      cellStyle: { ...commonStyle, textAlign: 'center' },
      headerStyle: { textAlign: 'center' },
      render: ({ start_date }) =>
        new Date(start_date).toLocaleDateString('en-US'),
    },
  ];

  const tableIcons = {
    Clear: forwardRef((prop, ref) => <Clear {...prop} ref={ref} />),
    Filter: forwardRef((prop, ref) => <FilterList {...prop} ref={ref} />),
    ResetSearch: forwardRef((prop, ref) => <Clear {...prop} ref={ref} />),
    Search: forwardRef((prop, ref) => <Search {...prop} ref={ref} />),
    SortArrow: forwardRef((prop, ref) => <ArrowDownward {...prop} ref={ref} />),
    FirstPage: forwardRef((prop, ref) => <FirstPage {...prop} ref={ref} />),
    LastPage: forwardRef((prop, ref) => <LastPage {...prop} ref={ref} />),
    NextPage: forwardRef((prop, ref) => <ChevronRight {...prop} ref={ref} />),
    PreviousPage: forwardRef((prop, ref) => (
      <ChevronLeft {...prop} ref={ref} />
    )),
    Export: forwardRef((prop, ref) => <SaveAlt {...prop} ref={ref} />),
  };

  return (
    <ThemeProvider theme={theme}>
      <MaterialTable
        data={socialChamps}
        columns={columns}
        icons={tableIcons}
        isLoading={isLoading}
        options={{
          filtering: false,
          pageSize: 10,
          toolbarButtonAlignment: 'left',
          pageSizeOptions: [10, 20, 50, 100, 200],
          sorting: false,
          draggable: false,
          showTitle: false,
          selection: true,
        }}
        actions={[
          {
            icon: () => (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: 4,
                }}
              >
                <Delete color="warning" />
                <Typography>Remove selected champ(s)</Typography>
              </div>
            ),
            tooltip: 'Remove selected champ(s)',
            position: 'toolbarOnSelect',
            onClick: (_event, data) => {
              confirm({
                title: 'Confirm Deletion',
                description:
                  'Are you sure you want to remove the selected champ(s)?',
                confirmationText: 'Yes',
                cancellationText: 'No',
              })
                .then(() => onDelete(data))
                .catch(() => {
                  /* do nothing on cancel */
                });
            },
          },
        ]}
      />
    </ThemeProvider>
  );
}

SocialChampsTable.propTypes = {
  socialChamps: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onDelete: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
};

SocialChampsTable.defaultProps = {
  isLoading: false,
};
