import React, { useContext, useEffect, useRef, useState } from 'react';
import { Button, Grid, Stack, Typography } from '@mui/material';
import { Upload } from '@mui/icons-material';
import socialChampsService from './SocialChamps.service';
import socialChampsApi from './SocialChamps.api';
import { AlertsContext } from '../shared/alerts/Alerts.context';
import SocialChampsTable from './SocialChampsTable';

export default function SocialChamps() {
  const [jsonResult, setJsonResult] = useState(null);
  const [errors, setErrors] = useState(null);
  const fileInputRef = useRef(null);
  const { showAlert } = useContext(AlertsContext);
  const [socialChamps, setSocialChamps] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const styles = {
    mainContainer: {
      marginTop: '6px',
    },
    fileUploadInput: {
      display: 'none',
    },
    uploadButton: {
      width: '200px',
    },
  };

  const fetchSocialChamps = async () => {
    setIsLoading(true);
    try {
      const response = await socialChampsApi.getSocialChamps();
      setSocialChamps(response);
    } catch (error) {
      showAlert('Failed to get social champs', 'error');
    } finally {
      setIsLoading(false);
    }
  };

  const deleteSocialChamps = async (data) => {
    const socialChampsData = data.map((e) => ({
      win: e.win,
      store_number: e.store_number,
    }));

    setIsLoading(true);
    socialChampsApi
      .deleteSocialChamps(socialChampsData)
      .then(() => {
        const deletedItems = new Set(
          socialChampsData.map((e) => `${e.win}-${e.store_number}`)
        );
        setSocialChamps(
          socialChamps.filter(
            (e) => !deletedItems.has(`${e.win}-${e.store_number}`)
          )
        );
        showAlert('Social Champs deleted correctly', 'success');
      })
      .catch((err) => {
        console.error('Failed to delete Social Champs', err);
        showAlert('Failed to delete Social Champs', 'error');
      })
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    fetchSocialChamps();
  }, []);

  useEffect(() => {
    if (!errors) return;
    showAlert(`${errors}`, 'error');
  }, [errors]);

  useEffect(() => {
    if (!jsonResult) return;
    fetchSocialChamps();
    showAlert(
      'File uploaded successfully!. Email with Social Champs sent.',
      'success'
    );
  }, [jsonResult]);

  return (
    <Grid container direction="column" spacing={4} style={styles.mainContainer}>
      <Grid item>
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="h4" align="left">
            Social Champs
          </Typography>
          <input
            type="file"
            ref={fileInputRef}
            style={styles.fileUploadInput}
            accept=".csv"
            onChange={(event) => {
              socialChampsService.handleFileUpload(
                event,
                setJsonResult,
                setErrors
              );
              if (fileInputRef.current) {
                fileInputRef.current.value = '';
              }
            }}
          />
          <Button
            variant="contained"
            color="primary"
            style={styles.uploadButton}
            endIcon={<Upload />}
            onClick={() => {
              fileInputRef.current.click();
            }}
          >
            Upload CSV
          </Button>
        </Stack>
      </Grid>
      <Grid item>
        <SocialChampsTable
          isLoading={isLoading}
          socialChamps={socialChamps}
          onDelete={deleteSocialChamps}
        />
      </Grid>
    </Grid>
  );
}
